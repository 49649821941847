.contributors {
	align-items: center;
	display: flex;
	gap: var(--spacing-4xs);
}

.avatarsList {
	display: flex;
	flex: none;
	list-style: none;
	margin: 0;
	padding: 0;
}

.singleContributorContainer {
	align-items: baseline;
	display: flex;
	flex: none;
	gap: var(--spacing-4xs);
}

.avatarListItem {
	flex-shrink: 0;
	margin-left: -0.3125rem;
	position: relative;
	z-index: 1;

	&:first-child {
		margin-left: 0;
	}
}

.contributorContainer {
	display: flex;
	flex-direction: column;
}

.contributorNameContainer {
	--color: var(--color-neutral-black);
	--font-family: var(--typography-eyebrow-family);
	--font-size: var(--typography-eyebrow-size);
	--font-weight: var(--typography-eyebrow-weight);
	--letter-spacing: var(--typography-eyebrow-letter-spacing);
	--line-height: var(--typography-eyebrow-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-eyebrow-desktop-family);
		--font-size: var(--typography-eyebrow-desktop-size);
		--font-weight: var(--typography-eyebrow-desktop-weight);
		--letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
		--line-height: var(--typography-eyebrow-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-transform: uppercase;
}

.contributorName {
	--color: var(--color-neutral-black);

	&:hover,
	&:focus,
	&:active {
		--color: var(--color);
	}
}

.isContributorSecondToLast {
	text-transform: lowercase;
}

.contributorRole,
.contributorsCredits {
	--color: var(--color-neutral-dark-70);
	--font-family: var(--typography-caption-family);
	--font-size: var(--typography-caption-size);
	--font-weight: var(--typography-caption-weight);
	--letter-spacing: var(--typography-caption-letter-spacing);
	--line-height: var(--typography-caption-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-caption-desktop-family);
		--font-size: var(--typography-caption-desktop-size);
		--font-weight: var(--typography-caption-desktop-weight);
		--letter-spacing: var(--typography-caption-desktop-letter-spacing);
		--line-height: var(--typography-caption-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.contributorCreditName {
	--color: var(--color-neutral-dark-70);

	&:hover,
	&:focus,
	&:active {
		--color: var(--color);
	}
}

@container style(--is-dark-mode: 1) {

	.contributorNameContainer,
	.contributorName {
		--color: var(--color-neutral-white);
	}

	.contributorRole,
	.contributorsCredits,
	.contributorCreditName {
		--color: var(--color-neutral-light-70);
	}
}

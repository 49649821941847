.commentsButton {
	--comments-cta-width: 100%;
	--comments-cta-desktop-width: 270px;

	white-space: nowrap;
	width: var(--comments-cta-width);

	@media (--bp-large) {
		width: var(--comments-cta-desktop-width);
	}

}

.overlay {
	--overlay-desktop-width: 33vw;
	--overlay-backdrop-background-color: #000;
	--overlay-backdrop-opacity: 0.5;
	--overlay-padding: 24px;
	--overlay-width: var(--overlay-xs-width);
	--overlay-xs-width: 100%;
	--overlay-translate-x: calc(var(--overlay-width) * -1);
	--background-color: var(--navigation-color-background);

	animation: close 0.5s forwards;
	background-color: var(--background-color);
	border: none;
	color: var(--color, currentColor);
	height: 100%;
	margin: auto;
	margin-left: 0;
	margin-right: auto;
	max-height: none;
	max-width: none;
	padding: var(--overlay-padding);
	position: fixed;
	transform: translateX(var(--overlay-translate-x));
	transition:
		display 0.5s allow-discrete,
		overlay 0.5s allow-discrete;
	width: var(--overlay-width);

	&:global(.right) {
		margin-left: auto;
		margin-right: 0;
		--overlay-translate-x: var(--overlay-width);
	}

	@media (--bp-large) {
		--overlay-width: var(--overlay-desktop-width);
	}

	&[open] {
		animation: open 0.5s forwards;
	}

	&::backdrop {
		background-color: var(--overlay-backdrop-background-color);
		opacity: var(--overlay-backdrop-opacity);
	}

	& .close {
		background: none;
		border: none;
		color: inherit;
		cursor: pointer;
		display: block;
		margin: 0 0 0 auto;
		outline: inherit;
		padding: 0;
	}

}

@keyframes open {

	from {
		transform: translateX(var(--overlay-translate-x));
	}

	to {
		transform: translateX(0);
	}
}

@keyframes close {

	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(var(--overlay-translate-x));
	}
}

.overlayContent {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.disclaimerContainer {
	--background-color: var(--disclaimer-color-background);
	--border-radius: var(--radius-m);
	--color: var(--disclaimer-color-text);
	--font-size: var(--disclaimer-typography-size);
	--font-family: var(--disclaimer-typography-family);
	--font-weight: var(--disclaimer-typography-weight);
	--letter-spacing: var(--disclaimer-typography-letter-spacing);
	--line-height: var(--disclaimer-typography-line-height);
	--vertical-spacing: var(--disclaimer-vertical-spacing);
	--padding: var(--spacing-m);

	background-color: var(--background-color);
	border-radius: var(--border-radius);
	color: var(--color);
	display: block;
	margin-block: var(--vertical-spacing);
	padding: var(--padding);

	& p {
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
	}
}

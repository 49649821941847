.keepReading {
	--color: currentColor;
	--font-size: var(--post-divider-typography-text-size);
	--vertical-spacing: var(--post-divider-vertical-spacing);
	--font-family: var(--post-divider-typography-text-family);
	--font-weight: var(--post-divider-typography-text-weight);
	--line-height: var(--post-divider-typography-text-line-height);
	--letter-spacing: var(--post-divider-typography-text-letter-spacing);

	@media (--bp-large) {
		--vertical-spacing: var(--post-divider-vertical-desktop-spacing);
	}

	align-items: center;
	color: var(--color);
	display: flex;
	justify-content: center;
	margin: calc(var(--vertical-spacing) + 40px) var(--margin-full-width);
	text-align: center;
	width: 100%;

	& span {
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		padding: 0 var(--spacing-xs);
		position: relative;
	}

	&::before,
	&::after {
		background-color: var(--color);
		content: "";
		flex: 1;
		height: 3px;
	}

	& .loading {
		display: inline-block;
		text-align: left;
		width: 8rem;

		@media (--bp-medium) {
			width: 10.5rem;
		}

		&::after {
			animation: arenaEllipsis steps(4, end) 2000ms infinite;
			content: "\2026";
			display: inline-block;
			overflow: hidden;
			vertical-align: bottom;
			width: 0;
		}
	}

	@media (--bp-medium) {
		--vertical-spacing: var(--post-divider-vertical-desktop-spacing);
	}

	@media (--bp-medium) {
		--font-size: var(--post-divider-typography-text-desktop-size);
		--vertical-spacing: var(--post-divider-vertical-desktop-spacing);

		span {
			--font-family: var(--post-divider-typography-text-desktop-family);
			--font-weight: var(--post-divider-typography-text-desktop-weight);
			--line-height: var(--post-divider-typography-text-desktop-line-height);
			--letter-spacing: var(--post-divider-typography-text-desktop-letter-spacing);
		}
	}
}

@keyframes arenaEllipsis {

	to {
		width: 1rem;
	}

}

@media (--bp-medium) {

	@keyframes arenaEllipsis {

		to {
			width: 1.5rem;
		}

	}

}

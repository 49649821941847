.videoWrapper {
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
}

.videoWrapper iframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.articleTimestamp {
	color: var(--color, currentColor);
	display: block;
	font-family: var(--typography-eyebrow-desktop-family);
	font-size: var(--typography-eyebrow-desktop-size);
	font-weight: var(--typography-eyebrow-desktop-weight);
	letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
	line-height: var(--typography-eyebrow-desktop-line-height);
	margin-block: 0;
	text-transform: uppercase;
}

.heroWrapper {
	background-color: var(--background);
	color: var(--split-hero-color-text);
	font-family: var(--font-instruments);
	padding: var(--spacing-l) var(--spacing-2xs);

	@media (--bp-large) {
		padding-block: var(--spacing-l-desktop);
	}
}

.heroWrapper:not([class*="is-style-"]) {
	--background: var(--split-hero-color-background);
}

.heroContainer {
	--max-width-wide: 85rem;

	margin-inline: auto;
	max-width: var(--max-width-wide);
	position: relative;
}

.heroBreadcrumbs {
	margin-bottom: var(--spacing-s);
}

.heroContent {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-m);

	@media (--bp-large) {
		column-gap: var(--spacing-s-desktop);
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(2, auto);
		row-gap: 0;
	}

	&.heroContentLeft .heroImageWrapper {

		@media (--bp-large) {
			grid-column: 2 / 3;
		}
	}

	&.heroContentWithoutMedia {

		@media (--bp-large) {
			grid-template-columns: 1fr;
		}
	}
}

.heroInfo {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-l);

	@media (--bp-large) {
		row-gap: var(--spacing-l-desktop);
	}
}

.heroInfoHead {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-xs);

	@media (--bp-large) {
		row-gap: var(--spacing-xs-desktop);
	}
}

.heroTitle {
	--font-family: var(--split-hero-typography-title-family);
	--font-size: var(--split-hero-typography-title-size);
	--font-weight: var(--split-hero-typography-title-weight);
	--letter-spacing: var(--split-hero-typography-title-letter-spacing);
	--line-height: var(--split-hero-typography-title-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-large) {
		--font-size: var(--split-hero-typography-title-desktop-size);
		--font-family: var(--split-hero-typography-title-desktop-family);
		--font-weight: var(--split-hero-typography-title-desktop-weight);
		--letter-spacing: var(--split-hero-typography-title-desktop-letter-spacing);
		--line-height: var(--split-hero-typography-title-desktop-line-height);

		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}
}

.heroDescription {
	--font-family: var(--split-hero-typography-subhead-family);
	--font-size: var(--split-hero-typography-subhead-size);
	--font-weight: var(--split-hero-typography-subhead-weight);
	--letter-spacing: var(--split-hero-typography-subhead-letter-spacing);
	--line-height: var(--split-hero-typography-subhead-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-large) {
		--font-size: var(--split-hero-typography-subhead-desktop-size);
		--font-family: var(--split-hero-typography-subhead-desktop-family);
		--font-weight: var(--split-hero-typography-subhead-desktop-weight);
		--letter-spacing: var(--split-hero-typography-subhead-desktop-letter-spacing);
		--line-height: var(--split-hero-typography-subhead-desktop-line-height);

		grid-column: 1 / 2;
		grid-row: 1 / 2;
		margin-block-start: 0;
	}
}

.heroArticleByline {

	@media (--bp-large) {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		margin-top: auto;
	}
}

.heroImageWrapper {
	margin: 0;
	position: relative;

	@media (--bp-large) {
		display: grid;
		grid-column: 1 / 2;
		grid-row: 1 / 3;
		grid-template-rows: subgrid;
	}

	& img {
		border-radius: var(--radius-media);
		display: block;
		height: auto;
		object-fit: cover;
		width: 100%;

		@media (--bp-large) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
	}
}

.heroImage {
	aspect-ratio: 4 / 3;
}

.heroImageCaption {
	--color: var(--split-hero-color-caption);
	--font-family: var(--split-hero-typography-caption-family);
	--font-size: var(--split-hero-typography-caption-size);
	--font-weight: var(--split-hero-typography-caption-weight);
	--letter-spacing: var(--split-hero-typography-caption-letter-spacing);
	--line-height: var(--split-hero-typography-caption-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-top: var(--spacing-3xs);

	@media (--bp-large) {
		--font-size: var(--split-hero-typography-caption-desktop-size);
		--font-family: var(--split-hero-typography-caption-desktop-family);
		--font-weight: var(--split-hero-typography-caption-desktop-weight);
		--letter-spacing: var(--split-hero-typography-caption-desktop-letter-spacing);
		--line-height: var(--split-hero-typography-caption-desktop-line-height);

		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
}

.heroImageCaptionText {
	display: block;

	& p {
		--font-family: var(--split-hero-typography-caption-family);
		--font-size: var(--split-hero-typography-caption-size);
		--font-weight: var(--split-hero-typography-caption-weight);
		--letter-spacing: var(--split-hero-typography-caption-letter-spacing);
		--line-height: var(--split-hero-typography-caption-line-height);

		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);

		&:not(:first-child) {
			margin-top: var(--spacing-3xs);
		}
	}

	& a {
		--color: var(--split-hero-color-caption);
	}
}

.heroImageSource {
	display: block;
	opacity: 0.6;

	@media (--bp-large) {
		margin-top: 0.125rem;
	}

	& a {
		--color: var(--split-hero-color-link);
		--font-size: var(--split-hero-typography-caption-size);

		font-size: var(--font-size);

		@media (--bp-large) {
			--font-size: var(--split-hero-typography-caption-desktop-size);
		}
	}
}

.outbrain-container {
	--margin-block: var(--outbrain-vertical-spacing);
	--min-height: 330px;
	--text-color: var(--color, currentColor);

	@media (--bp-medium) {
		--margin-block: var(--outbrain-vertical-desktop-spacing);
	}

	@media (--bp-large) {
		--min-height: 400px;
	}

	@media (--bp-xlarge) {
		--min-height: 450px;
	}

	background-color: var(--outbrain-color-background);
	color: var(--color, currentColor);
	display: block;
	margin: var(--margin-block);
	min-height: var(--min-height);
	padding: var(--spacing-s) var(--spacing-2xs) var(--spacing-l);
	position: relative;

	&:empty {
		display: none;
	}

	& :global(.ob-widget) {

		&:global(.ob-feed-layout) {
			--max-width-wide: 85rem;

			background-color: unset !important;
			margin-inline: auto;
			max-width: var(--max-width-wide);
		}
	}

	& :global(.ob-grid-layout) {

		& :global(.ob-rec-rtb-image) {
			border-radius: var(--radius-media);
		}

		& :global(.ob-rec-image) {
			border-radius: var(--radius-media);
		}
	}
}

a :is(.ob-what, .ob-widget-text, .ob-rec-text, .ob-rec-source) {
	color: var(--color, currentColor) !important;

	&:hover {
		color: var(--color, currentColor) !important;
	}
}

.avatar {
	align-items: center;
	aspect-ratio: var(--ar-1-1);
	background-color: var(--color-neutral-white);
	border-radius: var(--radius-icons);
	color: var(--color-neutral-black);
	display: flex;
	flex: none;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.xsmall {
	width: 1.5625rem;

	& .avatarIcon {
		height: 0.75rem;
		width: 0.75rem;
	}
}

.small {
	width: 2rem;

	& .avatarIcon {
		height: 1.25rem;
		width: 1.25rem;
	}
}

.medium {
	width: 6.25rem;

	& .avatarIcon {
		height: 3.75rem;
		width: 3.75rem;
	}
}

.large {
	width: 12.5rem;

	& .avatarIcon {
		height: 6.25rem;
		width: 6.25rem;
	}
}

.avatarImage {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

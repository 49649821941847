.contextualNavigation {
	--background-color: var(--navigation-color-background);
	--color: var(--navigation-color-text);
	--font-family: var(--typography-body-family);

	align-items: center;
	background-color: var(--background-color);
	box-shadow: var(--nav-shadow);
	display: flex;
	flex-wrap: nowrap;
	font-family: var(--font-family);
	font-size: var(--typography-body-desktop-size);
	gap: 0 var(--spacing-2xs);
	height: var(--sticky-height);
	padding: var(--spacing-2xs);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 50;

	@media (--bp-large) {
		justify-content: space-between;
	}
}

.contextualNavigationLogo {
	height: 20px;
	text-align: left;
	width: 20px;

	@media (--bp-large) {
		left: var(--spacing-2xs);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.contextualNavigationContinous .contextualNavigationLogo {
	display: none;

	@media (--bp-large) {
		display: block;
	}
}

.contextualNavigationLogoImg {
	background-color: var(--color-neutral-dark-10);
	display: block;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.contextualNavigationTitle {
	align-items: center;
	color: var(--color);
	display: flex;
	font-size: 0.75rem;
	font-weight: 500;
	gap: 0.75rem;
	overflow: hidden;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: 100%;

	@media (--bp-large) {
		font-size: 1rem;
		justify-content: center;
		margin-inline: 56px;
	}

	&::after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--background-color) 100%);
		content: "";
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 62px;
	}
}

.contextualNavigationUpNext {
	--background-color: #90d8e7;
	--border-radius: var(--radius-label);
	--color: var(--label-color-text, currentColor);
	--font-size: 0.75rem;
	--font-family: var(--label-typography-family);
	--font-weight: var(--label-typography-weight);
	--letter-spacing: var(--label-typography-letter-spacing);
	--line-height: var(--label-typography-line-height);

	align-items: center;
	background-color: var(--background-color, transparent);
	border: var(--border-width, 0) var(--border-style, none) var(--border-color, transparent);
	border-radius: var(--border-radius);
	color: var(--color);
	display: inline-flex;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-3xs);
	justify-content: center;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: var(--spacing-4xs) var(--spacing-3xs);
	text-transform: uppercase;

	& svg {
		height: 16px;
		width: 16px;
	}
}

.contextualNavigationCtas {
	display: flex;
	gap: var(--spacing-3xs);
	justify-content: flex-end;
	margin-left: auto;

	@media (--bp-large) {
		position: absolute;
		right: var(--spacing-2xs);
		top: 50%;
		transform: translateY(-50%);
	}

	& button {
		--color: var(--navigation-color-link);

		color: var(--color);
		cursor: pointer;
		height: 18px;
		width: 20px;
	}

	& button svg {
		display: block;
		height: 100%;
		width: 100%;
	}
}

.contextualNavigationContinous .contextualNavigationCtas {
	display: none;

	@media (--bp-large) {
		display: flex;
	}
}

.contextualNavigationProgress {
	bottom: 0;
	height: 2px;
	left: 0;
	position: absolute;
	width: 100%;
}

.contextualNavigationProgressInner {
	--background-color: var(--color-accent3);

	background-color: var(--background-color);
	height: 100%;
	transform: scaleX(0);
	transform-origin: left;
	transition: transform var(--transition-speed-fast) linear;
	width: 100%;
}

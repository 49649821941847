.loginForm {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);
	max-width: 21.875rem;
}

.loginFormHeader {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-2xs);
	text-align: center;
}

.loginFormLogo img {
	block-size: 2.5rem;
	display: block;
	inline-size: auto;
	margin-inline: auto;
	max-width: 100%;
}

.loginFormTitle {
	--font-family: var(--typography-h-s-desktop-family);
	--font-size: var(--typography-h-s-desktop-size);
	--font-weight: var(--typography-h-s-desktop-weight);
	--letter-spacing: var(--typography-h-s-desktop-letter-spacing);
	--line-height: var(--typography-h-s-desktop-line-height);
}

.loginFormDescription {
	--font-size: var(--typography-body-s-size);
}

.loginFormBody {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3xs);
}

.loginFormButton {
	inline-size: 100%;
}

.loginFormCheckbox {
	margin-block-start: var(--spacing-2xs);

	& input[type="checkbox"] {
		height: auto;
		width: auto;
	}
}

.loginFormSeparator {
	align-items: center;
	display: flex;
	font-size: var(--typography-caption-size);
	gap: var(--spacing-2xs);
	line-height: 1;

	&::before,
	&::after {
		background-color: var(--separator-color);
		content: "";
		display: block;
		flex-grow: 1;
		height: 1px;
		width: 100%;
	}
}

.loginFormSocialMethods {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3xs);
}
